import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Solid",
  sku: "SHW201",
  availability: "In stock",
  title: "OCSO Solid Men's Black shorts SHW201",
  mrpPrice: "1299",
  sellPrice: "599",
  disc:
    "Black in shading, these shorts by ocso advance regular scope of movement for great exercise. Including brandmark print on the correct crease and logo print on the left leg, these shorts have an elasticated midriff for a cozy fit. These shorts are designed with Swift Dri innovation for dampness the board..",
};
export default () => {
  return <ProdLayout data={data} />;
};
